import React, { useState, useEffect } from "react";
import "./Card2.css";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../Firebase/firebase";

import Modal from "./ModalCategory";
import { CategoryList } from "./Filterlists";
import BookMark from "../../Images/Bookmark (2).png";
import Verify from "../../Images/VerificationIcon.png";
import ReceivedLogo from "../../Images/acceptnew.svg";
import SentLogo from "../../Images/sent.svg";
import LocationLogo from "../../Images/LocationLogo.png";

const Card2 = ({
  name,
  logo,
  country,
  desc,
  userId,
  onClick,
  category,
  funding,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestSent, setRequestSent] = useState(0);
  const [requestReceived, setRequestReceived] = useState(0);
  const [isVerified, setIsVerified] = useState(false);

  const fetchUserConnectCounts = async (
    userId,
    setRequestSent,
    setRequestReceived
  ) => {
    try {
      // Reference to the UserConnects collection
      const connectsRef = collection(db, "UserConnects");

      // Create query to match userId
      const userQuery = query(connectsRef, where("userId", "==", userId));

      // Create query to match toUserId
      const toUserQuery = query(connectsRef, where("toUserId", "==", userId));

      // Run both queries in parallel
      const [userSnapshot, toUserSnapshot] = await Promise.all([
        getDocs(userQuery),
        getDocs(toUserQuery),
      ]);

      // Set state for requestSent and requestReceived
      setRequestSent(userSnapshot.size); // Count of requests sent (userId)
      setRequestReceived(toUserSnapshot.size); // Count of requests received (toUserId)
    } catch (error) {
      console.error("Error fetching data:", error);
      setRequestSent(0); // Default to 0 if error occurs
      setRequestReceived(0); // Default to 0 if error occurs
    }
  };

  // Fetch user verification status
  const fetchUserVerification = async (userId) => {
    try {
      const usersRef = collection(db, "User"); // Assuming your collection name is 'Users'
      const userQuery = query(usersRef, where("id", "==", userId));
      const userSnapshot = await getDocs(userQuery);
      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data(); // Get the first matched document
        setIsVerified(userData.verified); // Check if the user is verified
      }
    } catch (error) {
      console.error("Error fetching user verification:", error);
      setIsVerified(false); // Default to false if error occurs
    }
  };

  useEffect(() => {
    // Ensure selectedProject and userId exist
    if (userId) {
      fetchUserConnectCounts(userId, setRequestSent, setRequestReceived);
      fetchUserVerification(userId); // Fetch verification status
    }
  }, [userId, db]);
  const handleMoreClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const visibleCount = 4;
  const visibleCountm = 2;

  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll((prev) => !prev);
  };

  return (
    <>
      <div className="card-container">
        <div className="card-mobile">
          <div className="photo-name">
            <div className="card-logo" onClick={onClick}>
              <img
                className="cardlogo-img-m"
                src={logo}
                alt="logo"
                aria-placeholder="img"
              ></img>
            </div>
            <div className="header-card" onClick={onClick}>
              <div className="title-name-icon">
                <div className="title-name">{name} </div>
                {isVerified && (
                  <img src={Verify} alt="verified" className="verified-icon" />
                )}
              </div>
                <div className="funding-div"> 
              <div className="funding-btn">
                Funding Stage: 
                {funding?.length > 0 ? funding.join(", ") : "No funding data "}
              </div>
              </div>
            </div>
          </div>
          <div className="card2-details">
            <div className="Location-Card" onClick={onClick}>
              <div className="loc-div">
                <img src={LocationLogo} alt="location" />
                {country}
              </div>
              <div className="rcvd-div">
                {" "}
                <img
                  src={ReceivedLogo}
                  alt="Requests Received"
                  className="card2-icons-m"
                />
                Received: {requestReceived}
              </div>
              <div className="rcvd-div">
                <img
                  src={SentLogo}
                  alt="Requests Sent"
                  className="card2-icons-m"
                />
                Sent: {requestSent}
              </div>
            </div>

            <div className="body-content" onClick={onClick}>
              <div className="bio">{desc}</div>
            </div>

            <div className="cardin">
              <div className="cardin-list">
                <ul>
                  {category.slice(0, visibleCountm).map((name, index) => (
                    <li className="cardin-list-li" key={index}>
                      {name}
                    </li>
                  ))}
                  {category.length > visibleCountm && (
                    <li
                      style={{
                        fontWeight: "bold",
                        color: "rgba(26, 13, 171, 1)",
                      }}
                      onClick={handleMoreClick}
                    >
                      +{category.length - visibleCount} More
                    </li>
                  )}
                </ul>
              </div>
              {isModalOpen && (
                <Modal
                  maplist={category.slice(visibleCountm, category.length)}
                  onClose={closeModal}
                />
              )}
            </div>
          </div>
        </div>
        <div className="card-desktop">
          <div className="card-logo" onClick={onClick}>
            <img
              className="cardlogo-img"
              src={logo}
              alt="logo"
              aria-placeholder="img"
            ></img>
          </div>
          <div className="card2-content">
            <div className="card2-sub-content">
              <div className="header-card" onClick={onClick}>
                <div className="title-name-icon">
                  <div className="title-name">{name} </div>
                  {isVerified && (
                    <img
                      src={Verify}
                      alt="verified"
                      className="verified-icon"
                    />
                  )}
                </div>

                        {/* <img
                      src={BookMark}
                      alt="Bookmark"
                      className="bookmark"
                    /> */}
                <div className="funding-btn">
                    Funding Stage:&nbsp;
                            {funding?.length > 0
                    ? funding.join(", ")
                    : "No funding data available"}
                </div>
              </div>

              <div className="card2-details">
                <div className="Location-Card" onClick={onClick}>
                  <div className="loc-div">
                    <img
                      className="card2-icons"
                      src={LocationLogo}
                      alt="location"
                    />
                    {country}
                  </div>

                  <div className="rcvd-div">
                    <p className="received-block">
                      <img
                        src={ReceivedLogo}
                        alt="Request Received"
                        className="card2-icons"
                      />
                      Request Received:&nbsp;{" "}
                      <span className="rcvd-txt">
                        {String(requestReceived).padStart(2, "0")}
                      </span>
                    </p>
                  </div>
                  <div className="sent-div">
                    <p className="received-block">
                      <img
                        src={SentLogo}
                        alt="Requests Sent"
                        className="card2-icons"
                      />
                      Request Sent:&nbsp;{" "}
                      <span className="rcvd-txt">
                        {String(requestSent).padStart(2, "0")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="body-content" onClick={onClick}>
                  <div className="bio">{desc}</div>
                </div>

                <div className="cardin">
                  <div className="cardin-list">
                    <ul>
                      {category.slice(0, visibleCount).map((name, index) => (
                        <li className="cardin-list-li" key={index}>
                          {name}
                        </li>
                      ))}
                      {category.length > visibleCount && (
                        <li
                          style={{
                            fontWeight: "bold",
                            color: "rgba(26, 13, 171, 1)",
                          }}
                          onClick={handleMoreClick}
                        >
                          +{category.length - visibleCount} More
                        </li>
                      )}
                    </ul>
                  </div>
                  {isModalOpen && (
                    <Modal
                      maplist={category.slice(visibleCount, category.length)}
                      onClose={closeModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card2;
